import { pipe, constTrue, constNull } from "fp-ts/function";
import { option, set, eq } from "fp-ts";
import { useFormatMessage } from "../../intl";
import { PreContractualDocumentMeta } from "../documentAPI";
import { NonEmptyArray } from "fp-ts/NonEmptyArray";
import { NonEmptyString } from "io-ts-types/lib/NonEmptyString";
import { DocumentReadingConfirmProps } from "./DocumentItem";
import { getDocumentReadingStatus, getFirstUnreadDocument } from "./utils";
import { TaskEither } from "fp-ts/TaskEither";
import { useEffect, useState } from "react";
import { Option } from "fp-ts/Option";
import { DocumentDialog } from "./DocumentDialog";
import { ForeignDocumentDialog } from "../../UKontoSecondPart/Foreign/ForeignDocumentDialog";
import { ContractsPanel } from "./ContractsPanel";
import { useAppContext } from "../../useAppContext";
import { Box, Space } from "design-system";

export type ReadingConfirmProps =
  | {
      readingConfirmationType: "none";
    }
  | {
      readingConfirmationType: "status";
      documentsRead: Set<NonEmptyString>;
    }
  | {
      readingConfirmationType: "checkbox";
      documentsRead: Set<NonEmptyString>;
      onDocumentRead: (documentId: NonEmptyString) => TaskEither<unknown, void>;
      onDocumentUnread: (
        documentId: NonEmptyString
      ) => TaskEither<unknown, void>;
    };

type Props = {
  documents: NonEmptyArray<PreContractualDocumentMeta>;
  showErrors: boolean;
  isSigningStarted?: boolean;
  hasInsurance?: boolean;
  mustReadAllDocuments?: boolean;
  isForeignClient?: boolean;
} & ReadingConfirmProps;

export function Contracts(props: Props) {
  const formatMessage = useFormatMessage();
  const {
    config: { enableVirtualCardsLoanImpl },
  } = useAppContext();

  const [showContractModal, setShowContractModal] = useState<
    Option<PreContractualDocumentMeta>
  >(option.none);

  const [showForeignContractModal, setShowForeignContractModal] = useState<
    Option<PreContractualDocumentMeta>
  >(option.none);

  function getDocumentReadingConfirmProps(
    docId: NonEmptyString
  ): DocumentReadingConfirmProps {
    switch (props.readingConfirmationType) {
      case "none":
        return { readingConfirmationType: "none" };
      case "status":
        return {
          readingConfirmationType: "status",
          readStatus: getDocumentReadingStatus(
            props.documents,
            props.documentsRead
          )(docId),
        };
      case "checkbox":
        const isRead = pipe(props.documentsRead, set.elem(eq.eqStrict)(docId));
        return {
          readingConfirmationType: "checkbox",
          isRead,
          onRead: props.onDocumentRead(docId),
          onUnread: props.onDocumentUnread(docId),
          disabled:
            !isRead &&
            pipe(
              getFirstUnreadDocument(props.documents, props.documentsRead),
              option.fold(constTrue, d => d.docId !== docId)
            ),
        };
    }
  }

  const onDocumentOpen = (document: PreContractualDocumentMeta) => {
    setShowContractModal(option.some(document));
  };

  const onForeignDocumentOpen = (document: PreContractualDocumentMeta) => {
    setShowForeignContractModal(option.some(document));
  };

  useEffect(() => {
    if (props.readingConfirmationType === "status") {
      setShowContractModal(option.none);
    }
  }, [props.readingConfirmationType === "status" ? props.documentsRead : null]);

  const getUkontoContracts = (
    documents: NonEmptyArray<PreContractualDocumentMeta>
  ): NonEmptyArray<PreContractualDocumentMeta> | undefined => {
    const docArray = documents.filter(
      document =>
        option.isSome(document.docType) &&
        (document.docType.value === "FRAMEWORK_AGREEMENT" ||
          document.docType.value === "PRODUCT_CONTRACT_ACCOUNT" ||
          document.docType.value === "GDPR_CONSENT")
    );
    return docArray.length === 0
      ? undefined
      : (docArray as NonEmptyArray<PreContractualDocumentMeta>);
  };

  const getCashLoanContract = (
    documents: NonEmptyArray<PreContractualDocumentMeta>
  ): undefined | PreContractualDocumentMeta[] => {
    const docArray = documents.filter(
      document =>
        option.isSome(document.docType) &&
        document.docType.value === "LOAN_PRODUCT_CONTRACT_STANDARDLOAN"
    );
    return docArray.length === 0 ? undefined : docArray;
  };

  const getCurrentAccountContracts = (
    documents: NonEmptyArray<PreContractualDocumentMeta>
  ): NonEmptyArray<PreContractualDocumentMeta> | undefined => {
    const docArray = documents.filter(
      document =>
        option.isSome(document.docType) &&
        (document.docType.value === "FRAMEWORK_AGREEMENT_STANDARDLOAN" ||
          document.docType.value === "ACCOUNT_PRODUCT_CONTRACT_STANDARDLOAN")
    );
    return docArray.length === 0
      ? undefined
      : (docArray as NonEmptyArray<PreContractualDocumentMeta>);
  };

  const getOtherContracts = (
    documents: NonEmptyArray<PreContractualDocumentMeta>
  ): undefined | NonEmptyArray<PreContractualDocumentMeta> => {
    const docArray = documents.filter(
      document =>
        option.isSome(document.docType) &&
        document.docType.value !== "FRAMEWORK_AGREEMENT_STANDARDLOAN" &&
        document.docType.value !== "ACCOUNT_PRODUCT_CONTRACT_STANDARDLOAN" &&
        document.docType.value !== "LOAN_PRODUCT_CONTRACT_STANDARDLOAN" &&
        document.docType.value !== "FRAMEWORK_AGREEMENT" &&
        document.docType.value !== "PRODUCT_CONTRACT_ACCOUNT" &&
        document.docType.value !== "GDPR_CONSENT"
    );
    return docArray.length === 0
      ? undefined
      : (docArray as NonEmptyArray<PreContractualDocumentMeta>);
  };

  const getCashLoanAndOtherContracts = (
    documents: NonEmptyArray<PreContractualDocumentMeta>
  ): NonEmptyArray<PreContractualDocumentMeta> | undefined => {
    const cashLoanContracts = getCashLoanContract(documents);
    const otherContracts = getOtherContracts(documents);
    let contracts: PreContractualDocumentMeta[] = [];
    if (cashLoanContracts && otherContracts) {
      contracts = otherContracts.concat(cashLoanContracts);
    } else if (cashLoanContracts && !otherContracts) {
      contracts = cashLoanContracts;
    } else if (!cashLoanContracts && otherContracts) {
      contracts = otherContracts;
    }
    return contracts.length === 0
      ? undefined
      : (contracts as NonEmptyArray<PreContractualDocumentMeta>);
  };

  const extendNonEmptyArray = (
    documents1: NonEmptyArray<PreContractualDocumentMeta> | undefined,
    documents2: NonEmptyArray<PreContractualDocumentMeta> | undefined
  ): NonEmptyArray<PreContractualDocumentMeta> | undefined => {
    if (!documents1) return documents2;
    if (!documents2) return documents1;
    const array1 = documents1 as PreContractualDocumentMeta[];
    const array2 = documents2 as PreContractualDocumentMeta[];
    const result = array1.concat(array2);
    return result.length === 0
      ? undefined
      : (result as NonEmptyArray<PreContractualDocumentMeta>);
  };

  const ukontoContracts = getUkontoContracts(props.documents);
  const currentAccountContracts = getCurrentAccountContracts(props.documents);
  const cashLoanContractAndOtherContracts = getCashLoanAndOtherContracts(
    props.documents
  );
  const allContracts = extendNonEmptyArray(
    currentAccountContracts,
    cashLoanContractAndOtherContracts
  );
  const isCf = !!getCashLoanContract(props.documents);
  const useVirtualCardsContractLayout = enableVirtualCardsLoanImpl && isCf;

  return (
    <>
      {pipe(
        showContractModal,
        option.fold(constNull, document => {
          const confirmation = getDocumentReadingConfirmProps(document.docId);
          return (
            <DocumentDialog
              document={document}
              handleDismiss={() => setShowContractModal(option.none)}
              handleConfirm={
                confirmation.readingConfirmationType === "checkbox"
                  ? () => {
                      if (!confirmation.isRead) {
                        confirmation.onRead();
                      }
                    }
                  : undefined
              }
              sealed={document.signatureRequired}
              mustReadAllDocuments={props.mustReadAllDocuments}
              isForeignClient={props.isForeignClient}
            />
          );
        })
      )}
      {pipe(
        showForeignContractModal,
        option.fold(constNull, document => (
          <ForeignDocumentDialog
            onDismiss={() => setShowForeignContractModal(option.none)}
            title={document.docTitle}
            contractType={pipe(
              document.docType,
              option.fold(
                () => "PRODUCT_CONTRACT",
                docType => {
                  switch (docType) {
                    case "FRAMEWORK_AGREEMENT":
                      return "FRAMEWORK_AGREEMENT";
                    case "PRODUCT_CONTRACT_ACCOUNT":
                      return "PRODUCT_CONTRACT";
                    default:
                      return "PRODUCT_CONTRACT";
                  }
                }
              )
            )}
          />
        ))
      )}
      {useVirtualCardsContractLayout && currentAccountContracts && (
        <Box column>
          <ContractsPanel
            {...props}
            documents={currentAccountContracts}
            documentToCheckForReadingConfirmation={allContracts}
            showErrors={props.showErrors}
            onDocumentOpen={onDocumentOpen}
            onForeignDocumentOpen={onForeignDocumentOpen}
            title={formatMessage(
              "CF.ReadDocuments.Contracts.currentAccount.title"
            )}
            isSigningStarted={props.isSigningStarted}
            isForeignClient={props.isForeignClient}
          />
          <Space units={4} />
        </Box>
      )}
      {useVirtualCardsContractLayout && cashLoanContractAndOtherContracts && (
        <ContractsPanel
          {...props}
          documents={cashLoanContractAndOtherContracts}
          documentToCheckForReadingConfirmation={allContracts}
          showErrors={props.showErrors}
          onDocumentOpen={onDocumentOpen}
          onForeignDocumentOpen={onForeignDocumentOpen}
          title={formatMessage("CF.ReadDocuments.Contracts.cashLoan.title")}
          isSigningStarted={props.isSigningStarted}
          isForeignClient={props.isForeignClient}
        />
      )}
      {useVirtualCardsContractLayout && ukontoContracts && (
        <ContractsPanel
          {...props}
          documents={ukontoContracts}
          showErrors={props.showErrors}
          onDocumentOpen={onDocumentOpen}
          onForeignDocumentOpen={onForeignDocumentOpen}
          title={formatMessage("UKonto.ReadDocuments.Contracts.title")}
          isSigningStarted={props.isSigningStarted}
          isForeignClient={props.isForeignClient}
        />
      )}
      {!useVirtualCardsContractLayout && (
        <ContractsPanel
          {...props}
          documents={props.documents}
          showErrors={props.showErrors}
          onDocumentOpen={onDocumentOpen}
          onForeignDocumentOpen={onForeignDocumentOpen}
          title={formatMessage("UKonto.ReadDocuments.Contracts.title")}
          isSigningStarted={props.isSigningStarted}
          isForeignClient={props.isForeignClient}
        />
      )}
    </>
  );
}
