import {
  validators,
  NonNegativeBrand,
  LocalizedString,
  PositiveInteger,
} from "design-system";
import { Branded } from "io-ts";
import { useIntl } from "react-intl";
import { LocaleKey, useFormatMessage } from "../intl";
import { useCompanyNameAndIcoValidators } from "./CompanyField/utils";
import { constant } from "fp-ts/function";

export const useValidators = () => {
  const formatMessage = useFormatMessage();
  const { formatNumber } = useIntl();
  const { validIco, validName } = useCompanyNameAndIcoValidators();

  const defined = <T>(errorMessage?: LocalizedString) =>
    validators.defined<T>(
      errorMessage || formatMessage("Form.fieldError.required")
    );

  const definedNoExtract = <T>(errorMessage?: LocalizedString) =>
    validators.definedNoExtract<T>(
      errorMessage || formatMessage("Form.fieldError.required")
    );

  const maxLength = (max: number) =>
    validators.maxLength(
      max,
      formatMessage("Form.fieldError.maxLength", {
        max,
      })
    );

  const minLength = (min: number, errorMessage: LocaleKey) =>
    validators.minLength(min, formatMessage(errorMessage));

  const alphaNumeric = validators.alphaNumeric(
    formatMessage("Form.fieldError.alphaNumeric")
  );

  const validEmail = validators.validEmail(
    formatMessage("Form.fieldError.emailFormat")
  );

  const validPhone = validators.validPhone({
    lengthError: formatMessage(
      "Identification.otp.phoneNumber.validation.errorLength"
    ),
    formatError: formatMessage("Form.fieldError.phoneNumberFormat"),
  });

  const validDate = validators.validDate(
    formatMessage("Form.fieldError.invalidDateFormat")
  );

  const validBirthDate = validators.validBirthDate(
    formatMessage("Form.fieldError.invalidDateFormat"),
    formatMessage("Identification.personalData.birthDateMinError")
  );

  const minimalDate = (minimalValidDate: Date) =>
    validators.minDate(
      minimalValidDate,
      formatMessage("Form.fieldError.dateTooOld")
    );

  const maximalDate = (maximalValidDate: Date) =>
    validators.maxDate(
      maximalValidDate,
      formatMessage("Form.fieldError.invalidDateInFuture")
    );

  const validBirthNumber = validators.validBirthNumber(
    formatMessage("Identification.personalData.birthNumberFormatError")
  );

  const validNumber = validators.validNumber(
    formatMessage("Form.fieldError.validNumber")
  );

  const nonNegativeNumber = validators.inSequence(
    validNumber,
    validators.nonNegativeNumber(formatMessage("Form.fieldError.nonNegative"))
  );

  const nonNegativeInteger = validators.inSequence(
    validNumber,
    validators.nonNegativeInteger(
      formatMessage("Form.fieldError.nonNegativeInteger")
    )
  );

  const positiveInteger = validators.inSequence(
    validNumber,
    validators.positiveInteger(formatMessage("Form.fieldError.positiveInteger"))
  );

  const validInteger = validators.inSequence(
    validNumber,
    validators.fromPredicate(
      n => Number.isInteger(n),
      formatMessage("Form.fieldError.validInteger")
    )
  );

  const positiveNumber = validators.inSequence(
    validNumber,
    validators.positiveNumber(formatMessage("Form.fieldError.positive"))
  );

  const percentageNumber = validators.inSequence(
    validNumber,
    validators.percentageNumber(
      formatMessage("Form.fieldError.invalidPercentage")
    )
  );

  const nonBlankString = validators.nonBlankString(
    formatMessage("Form.fieldError.required")
  );

  const nonEmptyString = validators.nonEmptyString(
    formatMessage("Form.fieldError.required")
  );

  const digitsOnly = validators.digitsOnly(
    formatMessage("Form.fieldError.digitsOnly")
  );

  const validStreetNumber = validators.validStreetNumber(
    formatMessage("Form.fieldError.invalidStreetNumber")
  );

  const disableSpecialCharacters = validators.disableSpecialCharacters(
    formatMessage("Form.fieldError.disableSpecialCharacters")
  );

  const latinCharactersOnly = validators.latinCharactersOnly(
    formatMessage("Form.fieldError.latinCharactersOnly")
  );

  const validDay = validators.validDay(formatMessage("Form.fieldError.day"));

  const validPassword = validators.validPassword(
    formatMessage("Form.fieldError.required"),
    formatMessage("Form.passwordField.conditionsNotFulfilled")
  );

  const passwordMatches = validators.passwordMatches(
    formatMessage("Form.fieldError.required"),
    formatMessage("Form.passwordField.passwordsDoNotMatch")
  );

  const validPin = validators.validPin(
    formatMessage("Form.fieldError.required"),
    formatMessage("Form.fieldError.digitsOnly"),
    formatMessage("Form.passwordField.weakPin")
  );

  const pinMatches = validators.passwordMatches(
    formatMessage("Form.fieldError.required"),
    formatMessage("Form.passwordField.pinsDoNotMatch")
  );

  const modulo11Compliant = validators.modulo11Compliant(
    formatMessage("Form.fieldError.invalidAccountNumber")
  );

  const validCompanyIco = (
    allowUnknown: boolean,
    companyIcoIsValid?: boolean
  ) => {
    const commonValidator = validators.inSequence(
      maxLength(15),
      minLength(2, "Form.fieldError.invalidCompanyICO"),
      digitsOnly,
      nonBlankString
    );

    return allowUnknown
      ? commonValidator
      : validators.inSequence(
          commonValidator,
          companyIcoIsValid !== undefined
            ? validators.fromPredicate(
                constant(companyIcoIsValid),
                formatMessage("Form.fieldError.invalidCompanyICO")
              )
            : validIco(formatMessage("Form.fieldError.invalidCompanyICO"))
        );
  };

  const validAddressField = (maxLengthNr: number) => {
    const commonValidator = validators.inSequence(
      maxLength(maxLengthNr),
      nonBlankString
    );

    return commonValidator;
  };

  const validCompanyIcoRequired = (
    allowUnknown: boolean,
    companyIcoIsValid?: boolean
  ) =>
    validators.inSequence(
      nonEmptyString,
      validCompanyIco(allowUnknown, companyIcoIsValid)
    );

  const validCompanyName = (companyNameValid?: boolean) => {
    const commonValidator = validators.inSequence(
      maxLength(200),
      minLength(2, "Form.fieldError.invalidCompanyName"),
      nonEmptyString
    );

    return validators.inSequence(
      commonValidator,
      companyNameValid !== undefined
        ? validators.fromPredicate(
            constant(companyNameValid),
            formatMessage("Form.fieldError.invalidCompanyName")
          )
        : validName(formatMessage("Form.fieldError.invalidCompanyName"))
    );
  };

  const validCompanyNameRequired = (companyNameValid?: boolean) =>
    validators.inSequence(nonEmptyString, validCompanyName(companyNameValid));

  const minMax = <T extends number = number>(min: number, max: number) =>
    validators.fromPredicate<T>(
      n => n >= min && n <= max,
      formatMessage("Form.fieldError.minMaxAmount", {
        min: formatNumber(min),
        max: formatNumber(max),
      })
    );

  const amountMinMax = (min: number, max: number) =>
    validators.inSequence(
      validNumber,
      nonNegativeNumber,
      minMax<Branded<number, NonNegativeBrand>>(min, max)
    );

  const amountMinMaxWithNegative = (min: number, max: number) =>
    validators.inSequence(validNumber, minMax(min, max));

  const validOTP = <L extends PositiveInteger>(otpLength: L) =>
    validators.validOTP(
      otpLength,
      formatMessage("Form.fieldError.validOTP", {
        otpLength,
      })
    );

  const isChanged = <T>(initialValue: T) =>
    validators.isChanged(
      initialValue,
      formatMessage("Form.fieldError.isChanged")
    );

  const checked = (errorMessage?: LocalizedString) =>
    validators.checked(
      errorMessage || formatMessage("Form.fieldError.checked")
    );

  return {
    nonBlankString,
    defined,
    definedNoExtract,
    maxLength,
    alphaNumeric,
    validEmail,
    validPhone,
    validDate,
    validBirthDate,
    nonNegativeNumber,
    validNumber,
    nonNegativeInteger,
    positiveInteger,
    validInteger,
    positiveNumber,
    percentageNumber,
    digitsOnly,
    validStreetNumber,
    nonEmptyString,
    disableSpecialCharacters,
    latinCharactersOnly,
    validDay,
    validPassword,
    validPin,
    passwordMatches,
    pinMatches,
    modulo11Compliant,
    validCompanyIco,
    validAddressField,
    validCompanyName,
    amountMinMax,
    amountMinMaxWithNegative,
    validCompanyIcoRequired,
    validCompanyNameRequired,
    validOTP,
    isChanged,
    checked,
    validBirthNumber,
    minimalDate,
    maximalDate,
  };
};
