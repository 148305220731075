import {
  Box,
  buttonLink,
  Body,
  useIsTouchScreen,
  LocalizedString,
} from "design-system";
import { useFormatMessage } from "../../intl";
import { useState } from "react";
import { FilePDFDialog } from "../Dialogs/FilePDFDialog/FilePDFDialog";
import { pipe } from "fp-ts/function";
import { option, taskEither } from "fp-ts";
import { useCommand } from "../../useAPI";
import * as documentAPI from "../../Common/documentAPI";

type Props = {
  label?: LocalizedString;
  disabledLink?: boolean;
};

export function PersonalDataProcessingDisclaimer(props: Props) {
  const formatMessage = useFormatMessage();
  const isBranchExperienceTouchScreen = useIsTouchScreen();

  const [dataModalOpen, setDataModalOpen] = useState(false);

  const personalDataProcessingTemplates = useCommand(documentAPI.templates);
  const personalDataProcessingContent = useCommand(documentAPI.content);

  const disclaimerLabel = isBranchExperienceTouchScreen
    ? formatMessage(
        "PersonalDataProcessingDisclaimer.byContinuingDisclaimer.externalScreeen"
      )
    : formatMessage("PersonalDataProcessingDisclaimer.byContinuingDisclaimer");

  return (
    <Box>
      <Body size="small" weight="regular">
        {[
          props.label || disclaimerLabel,
          buttonLink(
            () => setDataModalOpen(true),
            formatMessage(
              "PersonalDataProcessingDisclaimer.personalDataProcessing"
            ),
            props.disabledLink
          ),
        ]}
      </Body>
      {dataModalOpen && (
        <FilePDFDialog
          onDismiss={() => setDataModalOpen(false)}
          title={formatMessage(
            "PersonalDataProcessingDisclaimer.personalDataProcessingTitle"
          )}
          file={pipe(
            personalDataProcessingTemplates({
              docsets: ["personalDataProcessing"],
            }),
            taskEither.chain(items =>
              personalDataProcessingContent({ docId: items[0].docId })
            )
          )}
          downloadUrl={option.none}
        />
      )}
    </Box>
  );
}
