import { TaskEither } from "fp-ts/TaskEither";
import { NonEmptyString } from "io-ts-types/lib/NonEmptyString";
import * as t from "io-ts";
import { pipe } from "fp-ts/function";
import { array, taskEither } from "fp-ts";
import { failure } from "io-ts/lib/PathReporter";
import { PositiveInteger } from "design-system";
import { Option } from "fp-ts/Option";
import { fromNullable } from "io-ts-types/lib/fromNullable";
import { SupportedLocales } from "./globalDomain";

export type BuildConfig = {
  apiEndpoint: Option<NonEmptyString>;
  apiPort: Option<PositiveInteger>;
};

const RemoteConfig = t.type(
  {
    UKontoPWSPageURL: NonEmptyString,
    UKontoPWSPageHeight: t.number,
    scannerAppUrl: NonEmptyString,
    settingsPageURL: NonEmptyString,
    tinByCountryURL: NonEmptyString,
    CRSCountryURL: NonEmptyString,
    FATCAURL: NonEmptyString,
    mockScanner: t.boolean,
    onlineBankingInfoURL: NonEmptyString,
    externalCommunication: t.boolean,
    enhancedMF20: t.boolean,
    environment: t.keyof({
      dev: true,
      sit: true,
      uat: true,
      prod: true,
      hf: true,
      ci: true,
    }),
    allowOverdraftSelection: t.boolean,
    biometricConsent: t.boolean,
    ezyPageURL: NonEmptyString,
    PWSURL: NonEmptyString,
    unicreditBranchesMapURL: NonEmptyString,
    PWSExistingClientURL: NonEmptyString,
    cookiesConsentInfoUrl: NonEmptyString,
    contactDetailsDuplicityCheck: fromNullable(t.boolean, true),
    OPUCEXAssignmentEnabled: fromNullable(t.boolean, true),
    multiplePackages: fromNullable(t.boolean, true),
    personalProfileDocumentUpload: fromNullable(t.boolean, true),
    personalProfileMarketingConsent: fromNullable(t.boolean, true),
    branchExperience: fromNullable(t.boolean, true),
    promotionsEnabled: fromNullable(t.boolean, false),
    shareWithClientButtonConfirmationDelayMs: fromNullable(t.number, 5000),
    shareWithClientButtonShareDelayMs: fromNullable(t.number, 2000),
    virtualKeyboardEnabled: fromNullable(t.boolean, true),
    editAddresses: fromNullable(t.boolean, true),
    documentBlurAcceptableScore: fromNullable(t.number, 60),
    specularAcceptableScore: fromNullable(t.number, 60),
    timeToBlurMaxTolerance: fromNullable(t.number, 9999),
    LogoutRedirectUrl: NonEmptyString,
    "MTG.reworkOfApplicationWorkaround": fromNullable(t.boolean, true),
    ukontoRemoteFlowEnabled: fromNullable(t.boolean, true),
    ukontoExistingClientsForceSMSOtpSignature: fromNullable(t.boolean, false),
    disableNewPackageExistingClientFromPersonalProfile: fromNullable(
      t.boolean,
      false
    ),
    windowMessageEventsLogs: fromNullable(t.boolean, false),
    forceNativePDFViewer: fromNullable(t.boolean, false),
    adaptiveEnabled: fromNullable(t.boolean, false),
    r5Enabled: fromNullable(t.boolean, false),
    "r5.2Enabled": fromNullable(t.boolean, false),
    r6Enabled: fromNullable(t.boolean, false),
    savingsAccountEnabled: fromNullable(t.boolean, false),
    localBirthNumberEnabled: fromNullable(t.boolean, false),
    greenMTGEnabled: fromNullable(t.boolean, false),
    enableMtgImprovementsUi: fromNullable(t.boolean, false),
    mobileUploadIdFeedBackInterval: fromNullable(t.number, 20),
    useNewMobileIdUploadUx: fromNullable(t.boolean, true),
    enableCashLoanId3PInBranch: fromNullable(t.boolean, false),
    r8EnableDeleteReqDoc: fromNullable(t.boolean, false),
    r8Enabled: fromNullable(t.boolean, false),
    enableUkontoRestore: fromNullable(t.boolean, false),
    r8EnableReuseReservedAccount: fromNullable(t.boolean, false),
    r8EnableSimpleRefinancing: fromNullable(t.boolean, false),
    r8EnableDiscountsOnOffer: fromNullable(t.boolean, false),
    r8EnableForeignCountriesSupport: fromNullable(t.boolean, false),
    r1EnableForeignNonResidentSupport: fromNullable(t.boolean, false),
    enableVirtualCardsImpl: fromNullable(t.boolean, false),
    enableVirtualCardsLoanImpl: fromNullable(t.boolean, false),
    physicalCardDefaultCheck: fromNullable(t.boolean, false),
    virtualCardTopImgURL: fromNullable(
      NonEmptyString,
      "dummy-url" as NonEmptyString
    ),
    virtualCardMcTopImgURL: fromNullable(
      NonEmptyString,
      "dummy-url" as NonEmptyString
    ),
    virtualCardMcMiddleImgURL: fromNullable(
      NonEmptyString,
      "dummy-url" as NonEmptyString
    ),
    virtualCardOpenImgURL: fromNullable(
      NonEmptyString,
      "dummy-url" as NonEmptyString
    ),
    virtualCardMcOpenImgURL: fromNullable(
      NonEmptyString,
      "dummy-url" as NonEmptyString
    ),
    physicalCardTopImgURL: fromNullable(
      NonEmptyString,
      "dummy-url" as NonEmptyString
    ),
    physicalCardMcTopImgURL: fromNullable(
      NonEmptyString,
      "dummy-url" as NonEmptyString
    ),
    /*    physicalCardMiddleImgURL: fromNullable(
      NonEmptyString,
      "dummy-url" as NonEmptyString
    ),*/
    physicalCardMcMiddleImgURL: fromNullable(
      NonEmptyString,
      "dummy-url" as NonEmptyString
    ),
    physicalCardOpenImgURL: fromNullable(
      NonEmptyString,
      "dummy-url" as NonEmptyString
    ),
    physicalCardMcOpenImgURL: fromNullable(
      NonEmptyString,
      "dummy-url" as NonEmptyString
    ),
    packageSelectionOpenMastercardImgUrl: fromNullable(
      NonEmptyString,
      "dummy-url" as NonEmptyString
    ),
    packageSelectionMiddleMastercardImgUrl: fromNullable(
      NonEmptyString,
      "dummy-url" as NonEmptyString
    ),
    packageSelectionTopMastercardImgUrl: fromNullable(
      NonEmptyString,
      "dummy-url" as NonEmptyString
    ),
    enableBankFee50FE: fromNullable(t.boolean, false),
    recaptchaEnabled: fromNullable(t.boolean, false),
    recaptchaPublicKey: fromNullable(t.string, ""),
    r6NewSironMapping: fromNullable(t.boolean, false),
    enableMastercardImpl: fromNullable(t.boolean, false),
  },
  "RemoteConfig"
);

export type RemoteConfig = t.TypeOf<typeof RemoteConfig>;

export function importConfigurations(
  apiEndpoint: NonEmptyString,
  locale: SupportedLocales,
  override: Partial<RemoteConfig> = {}
): TaskEither<unknown, RemoteConfig> {
  const fetchConfigurations = () =>
    fetch(
      `${apiEndpoint.replace(
        /\/+$/,
        ""
      )}/utilities/configurations/noauth?component=eShopFE`,
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Accept-Language": locale,
        },
      }
    ).then(body => body.json());

  return pipe(
    taskEither.tryCatch(fetchConfigurations, () => [] as t.Errors),
    taskEither.chainEitherKW(RemoteConfig.decode),
    taskEither.bimap(
      e => {
        if (array.isNonEmpty(e)) {
          console.error("Decoding error: ", failure(e).join("\n"));
        }
      },
      config => ({ ...config, ...override })
    )
  );
}
