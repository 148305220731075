import {
  Body,
  Card,
  Heading,
  LoadingButton,
  SmartphoneIcon,
  Stack,
} from "design-system";
import { boolean, taskEither } from "fp-ts";
import { constVoid, flow, pipe } from "fp-ts/function";
import { useFormatMessage } from "../../intl";
import { useCommand } from "../../useAPI";
import * as api from "../../SmartKey/api";

type Props = {
  onContinue: () => unknown;
  onApplicationRejected: () => unknown;
};

export function ExternalPushNotificationCard(props: Props) {
  const formatMessage = useFormatMessage();

  const sendNotification = useCommand(
    api.sendPushNoTransaction([
      "packages",
      "loans",
      "standard-loan",
      "authorizeWithPush",
    ])
  );

  return (
    <Card>
      <Stack units={5} vAlignContent="center" shrink>
        <SmartphoneIcon size="large" />
        <Stack column units={3} shrink>
          <Heading size="medium" weight="regular">
            {formatMessage("SmartKey.title.authorization")}
          </Heading>
          <Body size="small" weight="regular">
            {formatMessage("SmartKey.externalAuthorizationMessage")}
          </Body>
        </Stack>
        <LoadingButton
          variant="primary"
          labels={{
            normal: formatMessage("SmartKey.externalAuthorizationAction"),
            loading: formatMessage("SmartKey.externalAuthorizationAction"),
            success: formatMessage("SmartKey.externalAuthorizationAction"),
            error: formatMessage("SmartKey.externalAuthorizationAction"),
          }}
          action={pipe(
            sendNotification(),
            taskEither.bimap(
              constVoid,
              flow(
                ({ applicationRejected }) => applicationRejected,
                boolean.fold(props.onContinue, props.onApplicationRejected)
              )
            )
          )}
          size="default"
        />
      </Stack>
    </Card>
  );
}
