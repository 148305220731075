import { useState } from "react";
import { array, option, nonEmptyArray } from "fp-ts";
import { Panel, InlineLink, Stack, Box, FileIcon } from "design-system";
import { useFormatMessage } from "../../intl";
import { DocumentMeta } from "../documentAPI";
import { constNull, pipe, constTrue, flow } from "fp-ts/function";
import { DocumentDialog } from "./DocumentDialog";
import { TaskEither } from "fp-ts/TaskEither";
import * as api from "./api";
import { useRemoteData } from "../../useRemoteData";
import * as remoteData from "../../RemoteData";

type Props = {
  getTermsAndConditions: TaskEither<unknown, api.TermsAndConditionsOutput>;
  open?: boolean;
};

function Document({ document }: { document: DocumentMeta }) {
  const [showContractModal, setShowContractModal] = useState(false);

  return (
    <>
      <InlineLink icon={FileIcon} onClick={() => setShowContractModal(true)}>
        {document.docTitle}
      </InlineLink>
      {showContractModal && (
        <DocumentDialog
          document={document}
          handleDismiss={() => setShowContractModal(false)}
          sealed={false}
        />
      )}
    </>
  );
}

export function TermsAndConditions(props: Props) {
  const [isOpen, setOpen] = useState(props.open || false);
  const formatMessage = useFormatMessage();
  const termsAndConditions = useRemoteData(props.getTermsAndConditions);

  const renderContent = () => {
    return pipe(
      termsAndConditions,
      remoteData.fold(
        constNull,
        constNull,
        flow(
          nonEmptyArray.filter(item =>
            pipe(
              item.contractualDocument,
              option.fold(constTrue, item => !item)
            )
          ),
          option.fold(constNull, documents => (
            <Stack column units={4}>
              {pipe(
                documents,
                array.map(document => (
                  <Document document={document} key={document.docId} />
                ))
              )}
            </Stack>
          ))
        )
      )
    );
  };

  return (
    <Box column>
      <Panel
        status="active"
        isOpen={isOpen}
        onSelect={option.some(() => setOpen(!isOpen))}
        headerIcon={option.none}
        title={formatMessage("UKonto.ReadDocuments.TermsAndConditions.title")}
        content={renderContent}
      />
    </Box>
  );
}
